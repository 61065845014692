import React from 'react';

const DespreNoi = () => {
  return (
    <section id="despre-noi" className="py-12 bg-gray-100">
      <div className="container mx-auto px-6 text-center md:text-left">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">Despre Noi</h2>
        <p className="text-gray-600 text-lg mb-6">
        Înființată în 2018 ca un business de familie, WiWDepo a deschis primul nostru depozit cu un singur scop în minte: să oferim produse de calitate și servicii profesioniste pentru proiectele tale de construcții și amenajări. Suntem dedicați excelenței, iar fiecare zi ne aduce mai aproape de a deveni partenerul de încredere pentru tot mai mulți clienți.
        </p>
        <p className="text-gray-600">
        La WiWDepo, ne mândrim cu o echipă care pune pasiune în tot ceea ce face și cu o gamă largă de produse care respectă cele mai înalte standarde de calitate.
        </p>
      </div>
    </section>
  );
};

export default DespreNoi;
