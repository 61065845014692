import React from 'react';
import Navbar from './Navbar';
import '@fortawesome/fontawesome-free/css/all.min.css';
import HeroSection from './HeroSection';
import BrandLogos from './BrandLogos';
import DespreNoi from './DespreNoi';
import Servicii from './Servicii';
import Produse from './Produse';
import Testimoniale from './Testimoniale';
import Contact from './Contact';
import WhatsAppButton from './WhatsAppButton';
import Footer from './Footer';


function App() {
  return (
    <div className="App">
      <Navbar />
      <HeroSection />
      <BrandLogos />
      <DespreNoi />
      <Servicii />
      <Produse />
      <Testimoniale />
      <Contact />
      <WhatsAppButton />
      <Footer />
      
    </div>
  );
}

export default App;
