import React from 'react';
import brand1 from './assets/brand1.webp';
import brand2 from './assets/brand2.webp';
import brand3 from './assets/brand3.png';
import brand4 from './assets/brand4.webp';
// import brand5 from './assets/brand5.webp';
import brand6 from './assets/brand6.webp';

const BrandLogos = () => {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl font-bold mb-8 text-gray-800">Partenerii nostri</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4">
          <img src={brand1} alt="Brand 1" className="h-16 mx-auto" />
          <img src={brand2} alt="Brand 2" className="h-16 mx-auto" />
          <img src={brand3} alt="Brand 3" className="h-16 mx-auto" />
          <img src={brand4} alt="Brand 4" className="h-16 mx-auto" />
          {/* <img src={brand5} alt="Brand 5" className="h-16 mx-auto" /> */}
          <img src={brand6} alt="Brand 6" className="h-16 mx-auto" />
        </div>
      </div>
    </section>
  );
};

export default BrandLogos;
