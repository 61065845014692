import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    service: '',
    message: '', // Add this line to initialize the message field
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted', formData);

    emailjs
      .send(
        'service_i8jsm28',
        'template_g0607jc',
        {
          name: formData.name,
          phone: formData.phone,
          service: formData.service,
          message: formData.message, // Ensure the message is sent as part of the email
        },
        'NA7VAjVng54katjtz'
      )
      .then(
        (result) => {
          console.log('Message sent:', result.text);
          alert('Your message has been sent successfully!');
        },
        (error) => {
          console.error('Failed to send message:', error.text);
          alert('There was an error sending your message. Please try again.');
        }
      );

    // Clear the form data after submission
    setFormData({
      name: '',
      phone: '',
      service: '',
      message: '', // Clear the message as well
    });
  };

  return (
    <section id="contact" className="py-12 bg-white">
      <div className="container mx-auto px-6 grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Google Maps Iframe */}
        <div className="col-span-1">
          <h3 className="text-2xl font-semibold mb-4">Ne gasiti aici!</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2714.7770092644896!2d27.466281976678495!3d47.12304142124412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cae541fbd1c163%3A0x87975769177a4e3a!2sWiwdepo!5e0!3m2!1sro!2sro!4v1731069685036!5m2!1sro!2sro"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>

        {/* Contact Form */}
        <div className="col-span-1">
          <h3 className="text-2xl font-semibold mb-4">Contacteaza-ne acum</h3>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-gray-700">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>

            <div>
              <label htmlFor="phone" className="block text-gray-700">Phone</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>

            <div>
              <label htmlFor="service" className="block text-gray-700">Serviciu</label>
              <select
                id="service"
                name="service"
                value={formData.service}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded-lg"
              >
                <option value="">Select a service</option>
                <option value="etriere">Etriere</option>
                <option value="materiale_de_constructii">Materiale de Constructii</option>
                <option value="servicii_utilaje">Servicii Utilaje</option>
              </select>
            </div>

            <div>
              <label htmlFor="message" className="block text-gray-700">Scrie-ne un mesaj</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded-lg h-32 resize-none"
              ></textarea>
            </div>

            <div>
              <button
                type="submit"
                className="w-full bg-black text-white p-3 rounded-lg hover:bg-gray-800"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
