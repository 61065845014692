import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';


const testimonials = [
  { id: 1, name: 'Alexandru Ghinia', text: 'Băieți foarte saritori, au o gama destul de largă de produse la un preț bun. Recomand!' },
  { id: 2, name: 'Robert Mutihac', text: 'Recomand cu mare încredere! 😊✌️🔝🆙' },
  { id: 3, name: 'Ciprian Alexandru', text: 'Au transport gratuit si sunt foarte prompti!' },
];

const Testimoniale = () => {
  return (
    <section id="testimoniale" className="py-12 bg-gray-50">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-8">Testimoniale</h2>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000 }}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="p-6 bg-white rounded-lg shadow-lg">
              <div className="flex justify-center mb-2 text-yellow-500">
                  {[...Array(5)].map((_, index) => (
                    <FontAwesomeIcon key={index} icon={faStar} />
                  ))}
                </div>
                <p className="text-gray-700 italic mb-4">"{testimonial.text}"</p>
                <h3 className="text-lg font-semibold text-gray-800">- {testimonial.name}</h3>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimoniale;
