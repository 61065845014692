import React, { useState } from 'react';
import logo from './assets/logo.png'; // Adjust path as needed
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav className="bg-black text-white px-6 py-4 flex items-center justify-between relative z-50">
      {/* Logo */}
      <div>
        <img src={logo} alt="Business Logo" className="h-20" />
      </div>

      {/* Desktop Links */}
      <ul className="hidden md:flex space-x-6">
        <li className='hover:text-orange-500'><a href="#despre-noi">Despre Noi</a></li>
        <li className='hover:text-orange-500'><a href="#servicii">Servicii</a></li>
        <li className='hover:text-orange-500'><a href="#produse">Produse</a></li>
        <li className='hover:text-orange-500'><a href="#testimoniale">Testimoniale</a></li>
        <li className='hover:text-orange-500'><a href="#contact">Contact</a></li>
      </ul>

      {/* Mobile Menu Button */}
      <div className="md:hidden">
        <button onClick={toggleMenu}>
          {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <ul className="absolute top-16 left-0 w-full bg-black text-center space-y-4 py-4 md:hidden z-50">
          <li><a href="#despre-noi" onClick={toggleMenu}>Despre Noi</a></li>
          <li><a href="#servicii" onClick={toggleMenu}>Servicii</a></li>
          <li><a href="#produse" onClick={toggleMenu}>Produse</a></li>
          <li><a href="#testimoniale" onClick={toggleMenu}>Testimoniale</a></li>
          <li><a href="#contact" onClick={toggleMenu}>Contact</a></li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
