import React from 'react';
import { useInView } from 'react-intersection-observer';
import materiale from './assets/materiale.png';
import instalatii from './assets/sanitare.png';
import scule from './assets/scule.png';

const Produse = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true, threshold: 0.1 });

  const cardStyle = (inView) =>
    `p-6 bg-white shadow-lg rounded-lg transform transition duration-700 ${
      inView ? 'scale-100' : 'scale-90 opacity-0'
    }`;

  return (
    <section id="produse" className="py-12 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Produse</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div ref={ref1} className={cardStyle(inView1)}>
          <img
              src={materiale} 
              alt="Product 1"
              className="w-full h-48 object-cover rounded-t-lg mb-4"
            />
            <h3 className="text-xl font-semibold mb-4">Materiale de constructii</h3>
            <p className="text-gray-600">Găsește tot ce ai nevoie pentru construcții, de la ciment, mortar, și vopsea, până la lemn și alte materiale esențiale pentru proiectele tale de construcție. Calitate garantată și livrare rapidă!</p>
          </div>
          <div ref={ref2} className={cardStyle(inView2)}>
          <img
              src={scule} 
              alt="scule si instalatii"
              className="w-full h-48 object-cover rounded-t-lg mb-4"
            />
            <h3 className="text-xl font-semibold mb-4">Scule si unelte</h3>
            <p className="text-gray-600">Indiferent că ești profesionist sau amator, vei găsi scule și unelte de înaltă calitate pentru orice tip de lucrare. De la mașini de găurit la truse de unelte, avem tot ce îți trebuie pentru a-ți duce proiectul la bun sfârșit.</p>
          </div>
          <div ref={ref3} className={cardStyle(inView3)}>
          <img
              src={instalatii} 
              alt="instalatii sanitare"
              className="w-full h-48 object-cover rounded-t-lg mb-4"
            />
            <h3 className="text-xl font-semibold mb-4">Instalatii sanitare</h3>
            <p className="text-gray-600">Îți oferim o gamă variată de produse pentru instalații sanitare, de la țevi și robinete, până la accesorii esențiale pentru orice lucrare de instalații. Produse durabile și eficiente, pentru confortul și siguranța casei tale.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Produse;
