import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-12">
      <div className="container mx-auto px-6 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Social Media Icons */}
        <div className="flex flex-col items-start space-y-4">
          <h4 className="text-xl font-semibold">Follow Us</h4>
          <div className="flex space-x-6">
            <a href="https://www.facebook.com/profile.php?id=100054431097207" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
              <i className="fab fa-facebook-square text-3xl"></i>
            </a>
            <a href="https://www.instagram.com/wiwdepo" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
              <i className="fab fa-instagram text-3xl"></i>
            </a>
            <a href="https://www.tiktok.com/@wiwdepo" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
              <i className="fab fa-tiktok text-3xl"></i>
            </a>
          </div>
        </div>

        {/* Company Details */}
        <div className="flex flex-col items-start space-y-4">
          <h4 className="text-xl font-semibold">Detalii Companie</h4>
          <p>WiWDepo SRL</p>
          <p>Sat Buhalnita, Iași, Romania</p>
          <p>CUI: RO39461603</p>
        </div>

        {/* Contact Info */}
        <div className="flex flex-col items-start space-y-4">
          <h4 className="text-xl font-semibold">Contact</h4>
          <p className="flex items-center">
            <i className="fas fa-phone-alt mr-2"></i>
            +40 755 908 800
          </p>
          <p className="flex items-center">
            <i className="fas fa-envelope mr-2"></i>
            office@wiwdepo.ro
          </p>
        </div>
      </div>
      <div className="text-center mt-8">
        <p>&copy; 2024 WiWDepo SRL. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
