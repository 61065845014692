import React from 'react';
import { useInView } from 'react-intersection-observer';

const Servicii = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true, threshold: 0.1 });

  const cardStyle = (inView) =>
    `p-6 bg-white shadow-lg rounded-lg transform transition duration-700 ${
      inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
    }`;

  return (
    <section id="servicii" className="py-12 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Servicii</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div ref={ref1} className={cardStyle(inView1)}>
            <h3 className="text-xl text-center font-semibold mb-4">Etriere la comandă</h3>
            <p className="text-gray-600 indent-4">Realizăm etriere personalizate, adaptate specificațiilor fiecărui proiect, pentru a asigura un nivel maxim de siguranță și rezistență structurală.</p>
          </div>
          <div ref={ref2} className={cardStyle(inView2)}>
            <h3 className="text-xl text-center font-semibold mb-4">Debitare și prelucrare fier beton</h3>
            <p className="text-gray-600 indent-4">Oferim servicii de debitare și prelucrare a fierului beton conform nevoilor clientului, astfel încât să ușurăm munca pe șantier și să asigurăm o execuție rapidă a proiectelor.</p>
          </div>
          <div ref={ref3} className={cardStyle(inView3)}>
            <h3 className="text-xl text-center font-semibold mb-4">Transport la locație</h3>
            <p className="text-gray-600 indent-4">Asigurăm livrarea materialelor direct la locația proiectului, pentru confortul și eficiența clienților noștri.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Servicii;
