import React from 'react';
import Slider from 'react-slick';
import image1 from './assets/1.png';
import image2 from './assets/2.png';
import image3 from './assets/3.png';
import image4 from './assets/4.png';


const HeroSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section className="hero-section">
      <Slider {...settings}>
        <div>
          <img src={image1} alt="Slide 1" className="w-full h-auto" />
        </div>
        <div>
          <img src={image2} alt="Slide 2" className="w-full h-auto" />
        </div>
        <div>
          <img src={image3} alt="Slide 3" className="w-full h-auto" />
        </div>
        <div>
          <img src={image4} alt="Slide 4" className="w-full h-auto" />
        </div>
        {/* <div>
          <img src={image5} alt="Slide 5" className="w-full h-auto" />
        </div> */}
      </Slider>
    </section>
  );
};

export default HeroSection;
